import { Avatar, Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { IConsultor } from '../../../commons/types/usuatios';
import { authAtom } from '../../../states/usuarios/AuthState';

const ConsultorAvatar = styled(Avatar)({
  width: 56,
  height: 56,
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
});

const Container = styled(Box)(({ expanded }: { expanded: boolean }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: expanded ? '24px' : '16px',
  background: '#F5F5F5',
  width: expanded ? 'auto' : '88px',
  height: expanded ? '150px' : '88px',
  boxShadow: '0px 12px 16px -4px rgba(10, 13, 18, 0.08), 0px 4px 6px -2px rgba(10, 13, 18, 0.03)',
  borderRadius: expanded ? '16px' : '44px 44px 0px 44px',
  transition: 'all 1s ease',
}));

const Consultor: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const [expanded, setExpanded] = useState(false);
  const [consultorMaster, setConsultorMaster] = useState<IConsultor>(auth?.consultorResponsavel);

  return (
    <Container
      expanded={expanded}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      sx={{ position: 'absolute', top: 150, right: 50 }}
    >
      {expanded && (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <Typography variant="h6" sx={{ fontSize: '14px' }}>
            SEU CONSULTOR
          </Typography>
          <Divider sx={{ width: '100%' }} />
        </div>
      )}
      <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
        <ConsultorAvatar
          src={
            consultorMaster?.foto ||
            'https://static.vecteezy.com/ti/vetor-gratis/t1/4511281-foto-avatar-padrao-foto-espaco-reservado-perfil-imagem-vetor.jpg'
          }
          alt="Consultor"
        />
        {expanded && (
          <Box>
            <Typography variant="body1">{consultorMaster?.nome}</Typography>
            <Typography variant="body2">{consultorMaster?.email}</Typography>
            <Typography variant="body2">{consultorMaster?.celular}</Typography>
          </Box>
        )}
      </div>
    </Container>
  );
};

export default Consultor;
