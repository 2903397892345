import { useBaseActions } from '../base.actions';

function useDashboardActions() {
  const baseActions = useBaseActions('dashboard');

  return {
    get: baseActions.list,
  };
}

export { useDashboardActions };
