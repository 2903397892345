import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  Icon,
  InputLabel,
  LinearProgress,
  MenuItem,
  TableCell,
  TextField,
} from '@mui/material';
import { useIdiomasActions } from '../../../states/idiomas/idioma.actions';
import { IIdioma, IIdiomaConfig, INivelFluenciaConfig } from '../../../commons/types/crm';
import { useConfigNivelFluenciasActions } from '../../../states/configNivelFluencia/configNivelFluencia.actions';
import { useConfigIdiomasActions } from '../../../states/configIdiomas/configIdioma.actions';
import { GridEventListener } from '@mui/x-data-grid';
import { authAtom } from '../../../states/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { ButtonStato, IconDrawer, SelectStato } from '../../../commons/styleds/styleds';
import { GridItemCadastroBtnSalvar } from '../../../commons/styleds/grids';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  novoUsuario: boolean;
}

export interface IIdiomaContato {
  id?: number;
  idNivelFluencia?: number;
  idContato?: number;
  idIdioma?: number;
}

const IdiomasComponent: React.FC = () => {
  const idiomasActions = useIdiomasActions();
  const [idiomas, setIdiomas] = useState<IIdioma[]>([]);
  const configNivelFluenciasActions = useConfigNivelFluenciasActions();
  const [niveisFluencias, setNiveisFluencias] = useState<INivelFluenciaConfig[]>([]);
  const configIdiomasActions = useConfigIdiomasActions();
  const [idiomasConfig, setIdiomasConfig] = useState<IIdiomaConfig[]>([]);

  const auth = useRecoilValue(authAtom);

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    handleEditar(params.row);
  };

  const [open, setOpen] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingGet, setLoadingGet] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [idiomaSelected, setIdiomaSelected] = useState<number>();
  const [nivelFluenciaSelected, setNivelFluenciaSelected] = useState<number>();

  useEffect(() => {
    idiomasActions
      .list()
      .then((resp: any) => {
        setLoadingGet(false);
        setIdiomas(resp);
      })
      .catch((err: any) => {
        setLoadingGet(false);
        toast.warn(err);
      });

    configNivelFluenciasActions
      .list()
      .then((resp: any) => {
        setNiveisFluencias(resp);
      })
      .catch((err: any) => toast.warn(err));

    configIdiomasActions
      .list()
      .then((resp: any) => {
        setIdiomasConfig(resp);
      })
      .catch((err: any) => toast.warn(err));
  }, []);

  const handleEditar = (idioma: IIdiomaContato) => {
    setIdiomaSelected(idioma.idIdioma);
    setNivelFluenciaSelected(idioma.idNivelFluencia);
    setIdAtualizar(idioma.id);
    setOpen(true);
  };

  const openDrawerIdioma = (result: any) => {
    setIdiomaSelected(undefined);
    setNivelFluenciaSelected(undefined);
    setIdAtualizar(undefined);
    setOpen(result);
  };

  const handleApagar = (obj: IIdiomaContato) => {
    idiomasActions
      .delete(obj.id!)
      .then(() => {
        const sub = idiomas.filter((f) => f.idIdioma !== obj.idIdioma);
        setIdiomas(sub);
      })
      .catch((e) => {
        console.error(e);
        toast.error('Erro ao apagar');
      });
  };

  const adicionar = () => {
    if (Number(auth?.id) <= 0) {
      toast.warn('Id do usuário inválido.');
      return;
    }

    const data: IIdiomaContato = {
      idIdioma: idiomaSelected,
      idContato: Number(auth?.id),
      idNivelFluencia: nivelFluenciaSelected,
    };

    if (!idAtualizar) adicionarNovo(data);
    else {
      data.id = idAtualizar;
      editarIdioma(data);
    }
  };

  const adicionarNovo = (data: IIdiomaContato) => {
    idiomasActions
      .create(data!)
      .then((data) => {
        const newList = idiomas.concat(data!);
        setIdiomas(newList);
        openDrawerIdioma(false);
        toast.success('Adicionado com sucesso');
      })
      .finally(() => setLoading(false));
  };

  const editarIdioma = (data: IIdiomaContato) => {
    idiomasActions
      .update(data.id!, data)
      .then((data) => {
        const newList = idiomas.filter((f) => f.idIdioma !== data.id);
        setIdiomas(newList.concat(data));
        openDrawerIdioma(false);
        toast.success('Adicionado com sucesso');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Grid xs={12} md={12} sx={{ padding: '20px 0px' }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
          }}
        >
          <Typography sx={{ padding: '10px' }} variant="h5" component="h2">
            Idiomas
          </Typography>
          <Button
            style={{
              padding: '0px',
              height: '27px',
              minWidth: '27px',
              fontSize: '20px',
              borderRadius: '50%',
              marginTop: '10px',
            }}
            onClick={() => openDrawerIdioma(true)}
            variant="contained"
          >
            +
          </Button>
        </Grid>
        <Divider orientation="horizontal" />
      </Grid>

      <Grid container xs={12} md={12}>
        <Grid container xs={12} md={12} sx={{ padding: '10px' }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead sx={{ minWidth: 650, backgroundColor: '#0e95ba85' }}>
                <TableRow>
                  <TableCell>Idioma</TableCell>
                  <TableCell>Fluência</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: '#0392ca24' }}>
                {idiomas.map((row: IIdioma) => (
                  <TableRow
                    key={row.idIdioma + row.idNivelFluencia}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {idiomasConfig.find((f) => f.id == row.idIdioma)?.nome}
                    </TableCell>
                    <TableCell>
                      {niveisFluencias.find((f) => f.id == row.idNivelFluencia)?.nome}
                    </TableCell>
                    <TableCell>
                      <Grid container xs={12} md={12}>
                        {/* <Grid container xs={6} md={6}>
                                                    <Icon
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => handleEditar(idiomas.find(f => f.id === row.id!)!)}
                                                    >
                                                        {' '}
                                                        <EditIcon />{' '}
                                                    </Icon>
                                                </Grid> */}
                        <Grid container xs={6} md={6}>
                          <Icon sx={{ cursor: 'pointer' }} onClick={() => handleApagar(row)}>
                            {' '}
                            <DeleteIcon />{' '}
                          </Icon>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {loadingGet && (
            <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex' }}>
              <LinearProgress sx={{ width: '100%' }} />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Drawer
        style={{ minWidth: '250px' }}
        anchor={'right'}
        open={open}
        onClose={() => openDrawerIdioma(false)}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
        >
          <Grid
            item
            xs={12}
            md={12}
            style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}
          >
            <Grid item xs={2} md={2}>
              <IconDrawer onClick={() => openDrawerIdioma(false)}>close</IconDrawer>
            </Grid>
            <Grid item xs={10} md={10}>
              <Typography variant="h5">{'Adicionar Idioma na lista'}</Typography>
            </Grid>
          </Grid>

          <FormControl required fullWidth variant="outlined" sx={{ margin: '10px 0px' }}>
            <InputLabel id="demo-simple-select-label">Idioma</InputLabel>
            <SelectStato
              value={idiomaSelected}
              onChange={(e: any) => setIdiomaSelected(e.target.value)}
              required
              label="Consultor"
            >
              {idiomasConfig &&
                idiomasConfig!.map((idiomaSelect: IIdiomaConfig, index: number) => (
                  <MenuItem value={idiomaSelect.id} key={index}>
                    {idiomaSelect.nome}
                  </MenuItem>
                ))}
            </SelectStato>
          </FormControl>

          <FormControl required fullWidth variant="outlined" sx={{ margin: '10px 0px' }}>
            <InputLabel id="demo-simple-select-label">Grau de Fluência</InputLabel>
            <SelectStato
              value={nivelFluenciaSelected}
              onChange={(e: any) => setNivelFluenciaSelected(e.target.value)}
              required
              label="Grau de Fluência"
            >
              {niveisFluencias &&
                niveisFluencias!.map((grau: INivelFluenciaConfig, index: number) => (
                  <MenuItem value={grau.id} key={index}>
                    {grau.nome}
                  </MenuItem>
                ))}
            </SelectStato>
          </FormControl>

          <GridItemCadastroBtnSalvar
            item
            xs={11}
            md={11}
            sx={{
              position: 'fixed',
              right: '0px',
              bottom: '0px',
            }}
          >
            <ButtonStato variant="contained" onClick={adicionar}>
              Adicionar
            </ButtonStato>
          </GridItemCadastroBtnSalvar>
        </Grid>
      </Drawer>
    </>
  );
};

export default IdiomasComponent;
