import { Grid } from '@mui/material';

const TABS = [
  {
    id: '_THINK',
    label: 'THINK',
    statusPlan: 'programaStatusPlan1',
    statusThink: 'programaStatusThink1',
    statusGo: 'programaStatusGo1',
  },
  {
    id: '_PLAN',
    label: 'PLAN',
    statusPlan: 'programaStatusPlan2',
    statusThink: 'programaStatusThink2',
    statusGo: 'programaStatusGo2',
  },
  {
    id: '_GO_TO_MARKET',
    label: 'GO TO MARKET',
    statusPlan: 'programaStatusPlan3',
    statusThink: 'programaStatusThink3',
    statusGo: 'programaStatusGo3',
  },
];

interface ITabLinks {
  tipo: string;
  handleChangeTipo: Function;
}

const TabLinks: React.FC<ITabLinks> = ({ tipo, handleChangeTipo }) => {
  const getStatusClass = (tab: any) => {
    switch (tipo) {
      case 'PLAN':
        return tab.statusPlan;
      case 'THINK':
        return tab.statusThink;
      case 'GO_TO_MARKET':
        return tab.statusGo;
      default:
        return '';
    }
  };

  return (
    <Grid
      container
      xs={12}
      md={12}
      id="tabLinks"
      className="nav nav-pills nav-wizard"
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      {TABS.map((tab) => (
        <Grid
          key={tab.id}
          id={tab.id}
          className={getStatusClass(tab)}
          onClick={() => handleChangeTipo(tab.label.replace(/ /g, '_'))}
        >
          <Grid data-toggle="tab">{tab.label}</Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default TabLinks;
