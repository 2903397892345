import { styled } from 'styled-components';
import Dashboard from './Components/Dashboard';
import SuasEtapas from './Components/Etapas';

const Home: React.FC = () => {
  return (
    <DivBackground>
      <Dashboard />
      <SuasEtapas />
    </DivBackground>
  );
};

const DivBackground = styled.div`
  background: var(--gray-background);
  min-height: 90vh;
`;

export default Home;
