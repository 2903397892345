import { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { IComandaEtapaReunioes, IEtapa } from '../../../commons/types/crm';

export interface IAprovar {
  open: boolean;
  handleClose: () => void;
  onApprove: () => void;
  onReject: () => void;
  reuniao: IComandaEtapaReunioes;
  etapa: IEtapa;
  nota: number;
  setNota: Function;
  comentario: string;
  setComentario: Function;
  comentarioReprovar: string;
  setComentarioReprovar: Function;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

const Aprovar: React.FC<IAprovar> = ({
  open,
  handleClose,
  onApprove,
  onReject,
  reuniao,
  etapa,
  nota,
  setNota,
  comentario,
  setComentario,
  comentarioReprovar,
  setComentarioReprovar,
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [erroComentario, setErroComentario] = useState(false);

  const consultor = reuniao?.consultorResponsavel?.nome || 'Nome do Consultor';
  const data = new Date(reuniao.dtPrevista as any).toLocaleDateString();
  const dataInicio = `${data} ${new Date(reuniao.dtRealizadaInicio as any).toLocaleTimeString()}`;
  const dataFim = `${data} ${new Date(reuniao.dtRealizadaFim as any).toLocaleTimeString()}`;

  const rejeitarReuniao = () => {
    if (!comentario.trim()) {
      setErroComentario(true);
      return;
    }
    setErroComentario(false);
    setOpenConfirmation(false);
    onReject();
  };

  const handleConfirmationClose = () => {
    setErroComentario(false);
    setComentarioReprovar('');
    setOpenConfirmation(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} sx={{ borderRadius: '20px' }}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
            Aprovar Reunião
          </Typography>

          <Divider />

          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12}>
              <Typography>
                <strong>Consultor:</strong> {consultor}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <strong>Data Início:</strong> {dataInicio}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <strong>Data Fim:</strong> {dataFim}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ marginTop: '10px' }} />

          <Grid container spacing={1} sx={{ marginTop: '10px' }}>
            <Grid item xs={12}>
              <Typography>
                <strong>Avalie:</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>Nota:</Typography>
              <Rating
                name="nota"
                value={nota}
                onChange={(_, newValue) => setNota(newValue || 0)}
                max={5}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Comentário"
                fullWidth
                multiline
                rows={3}
                value={comentario}
                onChange={(e) => setComentario(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
            <Grid item>
              <Button variant="contained" color="success" onClick={onApprove}>
                Aprovar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="error" onClick={() => setOpenConfirmation(true)}>
                Reprovar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal open={openConfirmation} onClose={handleConfirmationClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" gutterBottom>
            CONFIRMAÇÃO
          </Typography>

          <Divider />

          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12}>
              <Typography>Nos informe o motivo da reprovação:</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: '10px' }}>
            <Grid item xs={12}>
              <TextField
                label="Comentário"
                fullWidth
                required
                multiline
                rows={3}
                value={comentarioReprovar}
                onChange={(e) => setComentarioReprovar(e.target.value)}
                error={erroComentario && comentarioReprovar.trim() === ''}
                helperText={
                  erroComentario && comentarioReprovar.trim() === ''
                    ? 'Comentário é obrigatório na reprovação'
                    : ''
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
            <Grid item>
              <Button variant="outlined" color="info" onClick={handleConfirmationClose}>
                Calcelar
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="error" onClick={rejeitarReuniao}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Aprovar;
