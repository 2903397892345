import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { styled } from 'styled-components';
import React from 'react';
import { authAtom } from '../../states/usuarios/AuthState';
import { GridContainer } from '../../commons/styleds/styleds';
import Box from '@mui/material/Box';
import { Link, useParams } from 'react-router-dom';
import { history } from '../../helpers/history';
import { useMenusActions } from '../../states/menus/menu.actions';
import { IArea, IConteudo, IMenu, ISecao } from '../../commons/types/base';
import { Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function createHTMLFromString(htmlString: any) {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IParams {
  id?: string;
}

const SecaoComponent: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const path = window.location.pathname;
  const [tipoSecao, setTipoSecao] = useState<number>(0);
  const [tipoSecaoTitulo, setTipoSecaoTitulo] = useState<boolean>(false);
  const [value, setValue] = React.useState(0);
  const { id } = useParams<IParams>();
  const [conteudos, setConteudos] = useState<IConteudo[]>([]);
  const [area, setArea] = useState<IArea>();
  const [secoes, setSecoes] = useState<ISecao[]>([]);
  const [secao, setSecao] = useState<ISecao>();

  const menusActions = useMenusActions();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
  };

  const handleChangeNext = () => {
    if (value < 6) setValue(value + 1);
  };

  const handleChangeBack = () => {
    if (value > 0) setValue(value - 1);
  };

  useEffect(() => {}, [auth]);

  useEffect(() => {
    let menuId = id;

    menusActions
      .get()
      .then((d) => {
        let menus: IMenu = d.resultado;

        let secao = menus.secoes.find((f) => f.id == Number(id));
        let area = menus.areas.find((f) => f.id == secao?.idArea!);
        let conteudos = menus.conteudos.filter(
          (f) =>
            f.idSecaos!.findIndex((f) => f.id == Number(id)) >= 0 &&
            f.idAreas!.findIndex((f) => f.id == area?.id) >= 0,
        );
        setArea(area);
        setSecao(secao);
        setConteudos(conteudos);
      })
      .catch((erro) => {
        // toast.error(erro);
      });
  }, [id]);

  return (
    <GridContainer
      container
      direction="row"
      spacing={0}
      sx={{
        display: 'flex',
        padding: '10px 15px 10px 10px',
        margin: '0px 30px !important',
        backgroundColor: 'white',
      }}
    >
      <Grid
        container
        direction="row"
        spacing={0}
        sx={{ paddingTop: '25px', display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}
        xs={12}
        md={12}
      >
        <Grid
          container
          xs={12}
          md={12}
          sm={12}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <Grid xs={12} md={12} sm={12} sx={{ borderBottom: '2.5px solid rgb(255 191 0)' }}>
            <Typography sx={{ padding: '10px 0px' }} variant="h4" component="h2">
              {secao?.nome}
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            md={12}
            sx={{ paddingBottom: '10px', display: 'flex', borderBottom: 'solid 1.5px #dfdfdf' }}
          >
            <Typography sx={{ padding: '10px' }} variant="caption">
              {createHTMLFromString(secao?.descricao)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
          {conteudos.map((item: IConteudo) => {
            return (
              <>
                <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
                  <Grid
                    container
                    xs={12}
                    md={12}
                    sx={{
                      paddingBottom: '10px',
                      display: 'flex',
                      borderBottom: 'solid 1.5px #dfdfdf',
                    }}
                  >
                    {item.anexo && (
                      <>
                        <Grid
                          container
                          xs={2}
                          md={2}
                          sx={{ display: 'flex', padding: '20px 10px' }}
                        >
                          <img
                            src={item.anexo}
                            alt="imagem"
                            width={'100%'}
                            style={{ maxWidth: '200px' }}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid container xs={10} md={10} sx={{ display: 'flex', padding: '20px 10px' }}>
                      <Link to={path + '/conteudos/' + item.id} style={{ cursor: 'pointer' }}>
                        {' '}
                        {item.titulo}{' '}
                      </Link>
                      {item.dtPublicacao && (
                        <Grid container xs={12} md={12} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          <IconButton
                            sx={{
                              display: 'flex',
                              alignContent: 'center',
                              flexWrap: 'wrap',
                            }}
                          >
                            <CalendarMonthIcon
                              sx={{
                                fontSize: '16px',
                                color: '#551a8b',
                              }}
                            />
                          </IconButton>
                          <span
                            style={{
                              display: 'flex',
                              alignContent: 'center',
                              flexWrap: 'wrap',
                            }}
                          >
                            {new Date(item.dtPublicacao)?.toLocaleDateString()}
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Grid>
    </GridContainer>
  );
};

const ButtonHome = styled(Button)`
  color: white !important;
  border-radius: 10px !important;
`;

export default SecaoComponent;
