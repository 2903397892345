import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { history } from '../../../helpers/history';

const ProgramaPPC: React.FC = () => {

  const handleChangeTipoLink = () => {
    history.push('/minha-transicao/programa/ppc');
  };

  return (
    <Typography>
      <Grid container xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
        <Typography sx={{ padding: "10px" }} variant="caption">
          Tem como objetivo levantar informações importantes para seu Consultor conhecê-lo (a) melhor, além de ser essencial para uma reflexão estruturada e melhor planejamento de sua carreira.
          <br /><br />

          O material de <b>Realizações e Competências</b> ajudará nessa etapa do processo.
          <br /><br />

          Revisitar sua trajetória profissional e realizações, mapeando competências e oportunidades de melhoria, é a base da nossa metodologia para a construção do plano de ação para os próximos ciclos da sua carreira. Este material também será utilizado para a elaboração do currículo, definição de estratégias de posicionamento e comunicação.
          <br /><br />

          Se tiver alguma dificuldade para o preenchimento, acesse nossa <b>FAQ do PPC</b> e veja se consegue tirar suas dúvidas.
          <br /><br />

          Concentre-se, clique no link abaixo para preencher seu perfil e bom trabalho!
          <br /><br />

          <Grid onClick={handleChangeTipoLink}>
            <Link to="/minha-transicao/programa/ppc">Clique aqui para preencher o formulário </Link>
            Etapa concluída </Grid>
        </Typography>
      </Grid>
    </Typography>
  )
}

export default ProgramaPPC;
