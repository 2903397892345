import { useBaseActions } from '../base.actions';
import { reuniaosAtom } from './ReuniaoState';
import { history } from '../../helpers/history';
import { authAtom } from './../usuarios/AuthState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAxiosWrapper } from '../../services/axiosWrapper';

function useReuniaosActions() {
  const authState = useRecoilValue(authAtom);
  const baseActions = useBaseActions('reunioes', reuniaosAtom, false);
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/assessorados`;
  const axiosWrapper = useAxiosWrapper();
  const setAuth = useSetRecoilState(authAtom);

  return {
    get,
    list: baseActions.list,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
    aprovar,
    reprovar,
  };

  async function get(id: number) {
    try {
      const recurso = await axiosWrapper.get(`${baseUrl}/${authState?.id}/comandas/${id}/reunioes`);
      return recurso;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }
  }

  async function aprovar(comandaId: number, id: number, obj: any) {
    await axiosWrapper.post(
      `${baseUrl}/${authState?.id}/comandas/${comandaId}/reunioes/${id}/aprovar`,
      obj,
    );
  }

  async function reprovar(comandaId: number, id: number, obj: any) {
    await axiosWrapper.post(
      `${baseUrl}/${authState?.id}/comandas/${comandaId}/reunioes/${id}/reprovar`,
      obj,
    );
  }
}

export { useReuniaosActions };
