import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { AppBar } from './MenuStyle';
import React, { useState } from 'react';
import { Avatar, Button, Grid, InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import styled from 'styled-components';
import Logo from '../../assets/STATO-INTOO-LOGO-MY-INTOO-VERS-1.png';
import { GridItem } from '../../commons/styleds/styleds';
import { history } from '../../helpers/history';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authAtom } from '../../states/usuarios/AuthState';



interface IHeader {
  open: boolean;
  handleClickDrawer: any;
  titulo: string;
}

const Header: React.FC<IHeader> = ({ open, handleClickDrawer, titulo }) => {
  const handleProfileClick = () => {
  };

  const setAuth = useSetRecoilState(authAtom);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [openSearch, setOpenSearch] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseSair = () => {
    setAnchorEl(null);
    localStorage.removeItem('usuario');
    setAuth(null);
    history.push('/login');
  };
  const handleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  return (
    <AppBar position='fixed' open={open}>
      <ToolbarStato>
        <IconButton
          aria-label='open drawer'
          onClick={handleClickDrawer}
          edge='start'
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {
          open ? <></> : <>
            <LogoGridItem
              item
              xs={2}
              md={2}
              sm={1}
              lg={1}
              style={{
                height: '110px', display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                marginLeft: '-20px'
              }}
            >
              {' '}
              <img src={Logo} alt='logo' width={'110px'} />
            </LogoGridItem>
          </>
        }
        <Grid container>
          <Grid item lg={7} md={6} sm={8}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'space-around'
            }}
          >
            <Typography
              style={{
                color: '#585858',
              }}
              variant='h3'
              noWrap
              component='div'
            >
              {titulo}
            </Typography>
          </Grid>

          <Grid item lg={4} md={5} sm={3}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
            }}>

          </Grid>
          <Grid item md={1} sm={1}>
            <IconButton onClick={handleClick}>
              <Avatar alt='Foto do Perfil' src={'https://miro.medium.com/v2/resize:fit:1400/1*g09N-jl7JtVjVZGcd-vL2g.jpeg'} />
            </IconButton>
            {/* <Button
              id="basic-button"
              aria-controls={openMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={handleClick}
            >
              Dashboard
            </Button> */}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>Inicio</MenuItem>
              <MenuItem onClick={handleClose}>Redefinir Senha</MenuItem>
              <MenuItem onClick={handleCloseSair}>Sair</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </ToolbarStato>
    </AppBar>
  );
};

const ToolbarStato = styled(Toolbar)`
  height: 100%;
`;

export const LogoGridItem = styled(GridItem)`
  background: transparent;
  width: 100%;
`;

export default Header;
