import { Router } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { history } from './helpers/history';
import { theme } from './commons/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <Routes />
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
