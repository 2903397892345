import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { styled } from 'styled-components';
import React from 'react';
import { history } from '../../helpers/history';
import { authAtom } from '../../states/usuarios/AuthState';
import { GridContainer } from '../../commons/styleds/styleds';
import LinearProgress from '@mui/material/LinearProgress';
import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material';
import { useProgramasActions } from '../../states/programas/programa.actions';
import { IComandaEtapaReuniaoResponse } from '../../commons/types/programa';
import { Link } from 'react-router-dom';
import { IComandaEtapaReunioes, IEtapa, StatusReuniaoEnum } from '../../commons/types/crm';
import ProgramaPPC from './components/ppc';
import TabLinks from './components/TabLinks';
import Etapa from './components/Etapas';
import { set } from 'react-hook-form';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  padding: 5,
  borderRadius: 5,
}));

const Programa: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const programasActions = useProgramasActions();

  const [expanded, setExpanded] = React.useState<number>(-1);
  const [porcetagem, setPorcetagem] = React.useState<number>(0);
  const [tipo, setTipo] = React.useState<string>('THINK');

  const [reunioes, setReunioes] = useState<IComandaEtapaReunioes[]>([]);
  const [etapas, setEtapas] = useState<IEtapa[]>([]);

  const handleChange = (event: any) => {
    setExpanded(expanded == event ? -1 : event);
  };

  const handleChangeTipo = (event: any) => {
    setTipo(event);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    setReunioes(auth?.comanda?.comandaEtapaReunioes!);

    const newEtapas = auth?.comanda?.etapas!.map((e: IEtapa) => {
      const newE = { ...e } as IEtapa;
      newE.reuniao = auth?.comanda?.comandaEtapaReunioes?.find((r) => r.comandaId === e.id)!;
      return e;
    });
    setEtapas(auth?.comanda?.etapas!);
  }, [auth]);

  useEffect(() => {
    let reunioCount = reunioes.length;
    let r = reunioes.filter((reuniao) => reuniao.status?.toLowerCase() != 'agendada').length;

    setPorcetagem((r / reunioCount) * 100);
  }, [reunioes]);

  return (
    <GridContainer
      container
      direction="row"
      spacing={0}
      sx={{
        display: 'flex',
        padding: '10px 15px 10px 10px',
        margin: '0px 30px !important',
        backgroundColor: 'white',
      }}
    >
      <Grid
        container
        direction="row"
        spacing={0}
        sx={{ paddingTop: '25px', display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}
        xs={12}
        md={12}
      >
        <Grid xs={12} md={12} sx={{ paddingBottom: '30px' }}>
          <Typography sx={{ padding: '10px' }} variant="h5" component="h2">
            {auth?.comanda?.plano}
          </Typography>
          <Divider orientation="horizontal" />
        </Grid>

        <Grid container xs={12} md={12} sx={{ paddingBottom: '10px' }}>
          <Grid container xs={12} md={12} sx={{ paddingBottom: '10px 0px' }}>
            <Grid container xs={12} md={12}>
              <Grid xs={6} md={6}>
                Conclusão Prevista para: {new Date(auth?.comanda?.dataFim!).toLocaleDateString()}
              </Grid>
              <Grid xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                Progresso atual: {porcetagem}%
              </Grid>
            </Grid>
            <Grid xs={12} md={12}>
              <BorderLinearProgress variant="determinate" value={porcetagem} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12} md={12} sx={{ padding: '10px 0px' }}>
          <Divider orientation="horizontal" sx={{ width: '100%' }} />
        </Grid>

        <TabLinks tipo={tipo} handleChangeTipo={handleChangeTipo} />

        <Grid container xs={12} md={12} sx={{ padding: '10px 0px' }}>
          <Divider orientation="horizontal" sx={{ width: '100%' }} />
        </Grid>

        <Grid
          container
          direction="row"
          xs={12}
          md={12}
          sx={{ display: 'flex', padding: '20px 0px' }}
        >
          <Grid container xs={12} md={12}>
            {etapas
              .filter((c) => c.idMetodologiaNavigation?.nome == tipo)
              .map((etapa, index) => (
                <Etapa
                  etapa={etapa}
                  reuniao={reunioes.find((r) => r.tbEtapaId === etapa.id)}
                  index={index}
                  expanded={expanded}
                  handleChange={handleChange}
                />
              ))}
          </Grid>
        </Grid>
      </Grid>
    </GridContainer>
  );
};

const ButtonHome = styled(Button)`
  background-color: #0091cf !important;
  color: white !important;
`;

export default Programa;
