import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  Icon,
  LinearProgress,
  TableCell,
  TextField,
} from '@mui/material';
import { useEnderecosActions } from '../../../states/enderecos/endereco.actions';
import { IEndereco } from '../../../commons/types/usuatios';
import { toast } from 'react-toastify';
import { authAtom } from '../../../states/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { ButtonStato, IconDrawer, TextFieldStato } from '../../../commons/styleds/styleds';
import { GridItemCadastroBtnSalvar } from '../../../commons/styleds/grids';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const empty: IEndereco = {
  id: 0,
  idContato: 0,
  idTipo: 3,
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
  pais: '',
  principal: false,
  ativo: true,
};

const EnderecosComponent: React.FC = () => {
  const enderecoActions = useEnderecosActions();
  const [enderecos, setEnderecos] = useState<IEndereco[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const [endereco, setEndereco] = useState<IEndereco>(empty);

  const auth = useRecoilValue(authAtom);

  const [cepValido, setCepValido] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingGet, setLoadingGet] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [loadingCep, setLoadingCep] = useState<boolean>(false);

  useEffect(() => {
    enderecoActions
      .list()
      .then((resp: any) => {
        setLoadingGet(false);
        setEnderecos(resp);
      })
      .catch((err: any) => {
        toast.warn(err);
        setLoadingGet(false);
      });
  }, []);

  const handleEditar = (endereco: IEndereco) => {
    setEndereco(endereco);
    setOpen(true);
  };

  const openDrawerEndereco = (result: any) => {
    setEndereco(empty);
    setOpen(result);
  };

  const getUrlViaCep = (cep: string) => {
    const Httpreq = new XMLHttpRequest();
    Httpreq.open('GET', 'https://viacep.com.br/ws/' + cep.replace('-', '') + '/json/', false);
    Httpreq.send(null);
    return Httpreq.responseText;
  };

  const handleApagar = (id: any) => {
    enderecoActions.delete(id).then(() => {
      const sub = enderecos.filter((f) => f.id !== id);
      setEnderecos(sub);
      toast.success('Endereço excluído');
    });
  };

  const submitEndereco = () => {
    adicionarNovo();
  };

  const adicionarNovo = async () => {
    setLoadingSave(true);
    endereco.idTipo = 3;
    if ((!endereco?.idContato || endereco?.idContato <= 0) && Number(auth?.id!) > 0) {
      endereco!.idContato = Number(auth?.id!);
    }

    if (endereco.id && endereco.id > 0) {
      const response = await enderecoActions.update(Number(endereco.id), endereco!);
      const newList = enderecos.filter((f) => f.id !== response!.id);
      setEnderecos(newList.concat(response!));
    } else {
      const response = await enderecoActions.create(endereco!);
      const newList = enderecos.concat(response!);
      setEnderecos(newList);
    }

    setEndereco(emptyEndereco(''));
    toast.success('Endereço salvo');
    openDrawerEndereco(false);
    setLoadingSave(false);
  };

  const handleGetCep = (event: any) => {
    if (event.target.value.length <= 9) {
      let input = event.target;
      input.value = getCepMask(input.value);
      setEndereco({ ...endereco, cep: input.value });
    }
    if (event.target.value.length < 9) {
      if (cepValido === true) {
        setEndereco(emptyEndereco(event.target.value));
        setCepValido(false);
      }
      return;
    }

    setLoadingCep(true);
    const enderecoCep = JSON.parse(getUrlViaCep(event.target.value));
    if (enderecoCep !== null && !enderecoCep.erro) {
      setCepValido(true);
      setEndereco({
        id: endereco.id,
        cep: event.target.value,
        bairro: enderecoCep.bairro,
        cidade: enderecoCep.localidade,
        endereco: enderecoCep.logradouro,
        estado: enderecoCep.uf,
      });
    } else {
      setCepValido(false);
      setEndereco(emptyEndereco(event.target.value));
    }
    setLoadingCep(false);
  };

  const emptyEndereco = (cep: string) => {
    return {
      id: endereco.id,
      idContato: Number(auth?.id!),
      idTipo: 3,
      cep: cep,
      bairro: '',
      cidade: '',
      endereco: '',
      estado: '',
    };
  };

  const getCepMask = (value: any) => {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{5})(\d)/, '$1-$2');
    return value;
  };

  return (
    <>
      <Grid xs={12} md={12} sx={{ padding: '20px 0px' }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
          }}
        >
          <Typography sx={{ padding: '10px' }} variant="h5" component="h2">
            Endereços
          </Typography>
          <Button
            style={{
              padding: '0px',
              height: '27px',
              minWidth: '27px',
              fontSize: '20px',
              borderRadius: '50%',
              marginTop: '10px',
            }}
            onClick={() => openDrawerEndereco(true)}
            variant="contained"
          >
            +
          </Button>
        </Grid>
        <Divider orientation="horizontal" />
      </Grid>

      <Grid container xs={12} md={12}>
        <Grid container xs={12} md={12} sx={{ padding: '10px' }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead sx={{ minWidth: 650, backgroundColor: '#0e95ba85' }}>
                <TableRow>
                  <TableCell>CEP</TableCell>
                  <TableCell>Endereço</TableCell>
                  <TableCell>Numero</TableCell>
                  <TableCell>Complemento</TableCell>
                  <TableCell>Cidade</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Principal</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: '#0392ca24' }}>
                {enderecos.map((row: IEndereco) => (
                  <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.cep}
                    </TableCell>
                    <TableCell>{row.endereco}</TableCell>
                    <TableCell>{row.numero}</TableCell>
                    <TableCell>{row.complemento}</TableCell>
                    <TableCell>{row.cidade}</TableCell>
                    <TableCell>{row.uf}</TableCell>
                    <TableCell>{row.principal}</TableCell>
                    <TableCell>
                      <Grid container xs={12} md={12}>
                        <Grid container xs={6} md={6}>
                          <Icon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleEditar(enderecos.find((f) => f.id === row.id!)!)}
                          >
                            {' '}
                            <EditIcon />{' '}
                          </Icon>
                        </Grid>
                        <Grid container xs={6} md={6}>
                          <Icon sx={{ cursor: 'pointer' }} onClick={() => handleApagar(row.id!)}>
                            {' '}
                            <DeleteIcon />{' '}
                          </Icon>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {loadingGet && (
            <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex' }}>
              <LinearProgress sx={{ width: '100%' }} />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Drawer
        style={{ minWidth: '250px' }}
        anchor={'right'}
        open={open}
        onClose={() => openDrawerEndereco(false)}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
        >
          <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={2} md={2}>
              <IconDrawer onClick={() => openDrawerEndereco(false)}>close</IconDrawer>
            </Grid>
            <Grid item xs={10} md={10}>
              <Typography variant="h5">{'Adicionar Endereço na Lista'}</Typography>
            </Grid>
          </Grid>

          <FormControl id="cep" required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
            <TextFieldStato
              type="text"
              label="Cep"
              value={endereco?.cep}
              onChange={(e) => handleGetCep(e)}
              inputProps={{ maxLength: 9 }}
            />
          </FormControl>

          {loadingCep && <LinearProgress />}

          <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
            <TextFieldStato
              id={'EnderecoInput'}
              type="Endereco"
              label="Endereço"
              value={endereco?.endereco}
              disabled={cepValido}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEndereco({ ...endereco, endereco: event.target.value });
              }}
            />
          </FormControl>

          <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
            <TextFieldStato
              id={'EnderecoInput'}
              type="numero"
              label="Número"
              value={endereco?.numero}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEndereco({ ...endereco, numero: event.target.value });
              }}
            />
          </FormControl>

          <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
            <TextFieldStato
              id={'EnderecoInput'}
              type="complemento"
              label="Complemento"
              value={endereco?.complemento}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEndereco({
                  ...endereco,
                  complemento: event.target.value,
                });
              }}
            />
          </FormControl>

          <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
            <TextFieldStato
              id={'EnderecoInput'}
              type="bairro"
              label="Bairro"
              disabled={cepValido}
              value={endereco?.bairro}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEndereco({ ...endereco, bairro: event.target.value });
              }}
            />
          </FormControl>

          <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
            <TextFieldStato
              id={'EnderecoInput'}
              type="cidade"
              label="Cidade"
              disabled={cepValido}
              value={endereco?.cidade}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEndereco({ ...endereco, cidade: event.target.value });
              }}
            />
          </FormControl>

          <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
            <TextFieldStato
              id={'EnderecoInput'}
              type="estado"
              label="Estado"
              disabled={cepValido}
              value={endereco?.estado}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEndereco({ ...endereco, estado: event.target.value });
              }}
            />
          </FormControl>

          <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
            <TextFieldStato
              id={'EnderecoInput'}
              type="pais"
              label="País"
              value={endereco?.pais}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEndereco({ ...endereco, pais: event.target.value });
              }}
            />
          </FormControl>

          {loadingSave && <LinearProgress />}

          <GridItemCadastroBtnSalvar
            item
            xs={11}
            md={11}
            sx={{
              position: 'fixed',
              right: '0px',
              bottom: '0px',
            }}
          >
            <ButtonStato variant="contained" onClick={submitEndereco}>
              Adicionar
            </ButtonStato>
          </GridItemCadastroBtnSalvar>
        </Grid>
      </Drawer>
    </>
  );
};

export default EnderecosComponent;
