import { Dayjs } from 'dayjs';
import { IBaseEntity } from './base';
import { IdNome } from './programa';

export enum StatusReuniaoEnum {
  PENDENTE = 'PENDENTE',
  AGENDADA = 'AGENDADA',
  PENDENTE_APROVACAO = 'PENDENTE_APROVACAO',
  APROVADA = 'APROVADA',
  CANCELADA = 'CANCELADA',
}

export interface IComandaEtapaReunioes extends IBaseEntity {
  comandaId: number;
  tbEtapaId: number;
  dtPrevista?: Date | Dayjs;
  dtRealizadaInicio?: Date | Dayjs;
  dtRealizadaFim?: Date | Dayjs;
  valorHora?: number;
  etapaExecutivo: string | null;
  idConsultorResponsavel: number;
  consultorResponsavel?: any;
  idUsuarioCadastro: number;
  idUsuarioAlteracao: string | null;
  onboarding: boolean;
  status: StatusReuniaoEnum;
  tempoTotal?: string;
  valorTotal?: string;
  nota?: number;
  comentarioContato?: string;
}

export interface IReuniao extends IBaseEntity {
  nomeAssessorado: string;
  idAssessorado: number;
  nomeConsultor: string;
  idConsultor: number;
  nomeConsultorMaster: string;
  idConsultorMaster: number;
  dtPrevista?: Date;
  dtRealizadaInicio?: Date;
  dtRealizadaFim?: Date;
  valorHora?: number;
  onboarding: boolean;
  status: StatusReuniaoEnum;
  tbEtapaId: number;
  tempoTotal?: string;
  valorTotal?: string;
}

export interface IContato extends IBaseEntity {
  nome: string;
  email: string;
  cpf?: string;
}

export interface IMetodologia extends IBaseEntity {
  nome?: string;
  texto?: string;
}

export interface IEtapa extends IBaseEntity {
  nome?: string;
  texto?: string;
  idPrazo?: string;
  ordem?: number;
  idUsuario?: string;
  imagem?: string;
  idMetodologia?: string;
  objetoForms?: string;
  idMetodologiaNavigation?: IMetodologia;
  reuniao?: IComandaEtapaReunioes;
}

export interface IComanda extends IBaseEntity {
  dataInicio?: string;
  dataFim?: string;
  status?: number;
  contato?: IContato;
  etapas?: IEtapa[];
  comandaEtapaReunioes?: IComandaEtapaReunioes[];
  plano?: string;
  modeloComercial?: string;
  projetoConfidencial: boolean;
  tbEmpresaId?: number;
  idConsultorOnboarding?: number;
  valorHora: number | undefined;
  comercialResponsavel?: string;
  empresaCliente?: string;
  rhRepresentante?: string;
  estimativaAdesao?: string;
  bonusSalarios: number;
  custoProjeto: number;
  ultimaEmpresaTrabalho?: string;
  segmento?: string;
  regiaoMoradia?: string;
  regiaoRestricaoTrabalho?: string;
  modeloTrabalho: string;
  restricaoEmpresa: boolean;
  empresaDaRestricao?: string;
  restricaoSegmento: boolean;
  segmentoDaRestricao?: string;
  modeloContrato?: string;
  recolocadoIndependeteFonte?: boolean;
  recolocadoStato?: boolean;
  contatoRelatorio?: string;
  planejamento?: boolean;
  relacionamentoMercado?: boolean;
  coachingIntegração?: boolean;
  // historicoAcoes?: IHistoricoAcoes[];
}

export interface IAcao {
  id: number;
  idTipo: number;
  idComanda?: number;
  dtPrevista?: Date | Dayjs;
  observacoes?: string;
  anexo?: string;
  anexoFile?: any;
  realizada?: boolean;
  habilitaCliente?: boolean;
  habilitaExecutivo?: boolean;
  notificarMesa?: boolean;
  notificarPool?: boolean;
  ativo?: boolean;
}

export interface IIdiomaConfig extends IdNome {}

export interface IIdioma {
  id?: number;
  idIdioma: number;
  idContato: number;
  idNivelFluencia: number;
}

export interface INivelFluenciaConfig extends IdNome {}

export interface IGraduacao extends IdNome {
  id?: number;
  tipo?: string;
  nome?: string;
  programa?: string;
  graduado?: boolean;
  mesGraduacao?: string;
  anoGraduacao?: number;
  descricao?: string;
  IdContato?: number;
}

export interface IEmpresa {
  id: number;
  razaosocial: string;
  cnpj: string;
  nomefantasia: string;
  matriz: boolean;
  ativo: boolean;
}

export interface INivelPosicao {
  id: number;
  idParent: number;
  nome: string;
}

export interface IContatoHistoricoEmpresa {
  id: number;
  contatoId: number;
  empresaId: number;
  empresaIdNavigation: IEmpresa;
  posicoes: IContatoHistoricoEmpresaPosicoes[];
}

export interface IContatoHistoricoEmpresaPosicoes {
  id: number;
  historicoEmpresaId: number;
  cargo: string;
  areaId: number;
  nivelId: number;
  escopoId: number;
  cargoAtual: boolean;
  dataInicio: Date | Dayjs;
  dataTermino: Date | Dayjs;
  superior: string;
  numeroDeSubordinados: number;
  responsabilidades: string;
  realizacoes: string;
  motivoSaida: string;
}
